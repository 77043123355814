package com.bkahlert.hello.fritz2

import com.bkahlert.hello.fritz2.components.heroicons.OutlineHeroIcons
import com.bkahlert.hello.fritz2.components.icon
import com.bkahlert.kommons.js.trace
import com.bkahlert.kommons.uri.Uri
import com.bkahlert.kommons.uri.toUri
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.Keys
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Shortcut
import dev.fritz2.core.Store
import dev.fritz2.core.Tag
import dev.fritz2.core.Window
import dev.fritz2.core.WithDomNode
import dev.fritz2.core.classes
import dev.fritz2.core.lensOf
import dev.fritz2.core.multiple
import dev.fritz2.core.rows
import dev.fritz2.core.selected
import dev.fritz2.core.shortcutOf
import dev.fritz2.core.transition
import dev.fritz2.core.type
import dev.fritz2.core.value
import dev.fritz2.core.values
import dev.fritz2.headless.components.checkboxGroup
import dev.fritz2.headless.components.listbox
import dev.fritz2.headless.components.popOver
import dev.fritz2.headless.components.switchWithLabel
import dev.fritz2.headless.foundation.Aria
import dev.fritz2.headless.foundation.OpenClose
import dev.fritz2.headless.foundation.utils.popper.Placement
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.ValidationMessage
import dev.fritz2.validation.valid
import kotlinx.browser.document
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import org.w3c.dom.Element
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLOptionElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.asList
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent

private fun Tag<HTMLButtonElement>.renderAsSwitch(enabled: Flow<Boolean>) {
    className(
        classes(
            "relative inline-flex h-6 w-11",
            "cursor-pointer rounded-full",
            "form-checkbox form-field border-2 bg-gray-200 text-gray-700",
            "transition-colors ease-in-out duration-200",
        )
    )
    span(
        classes(
            "inline-block h-5 w-5",
            "rounded-full border-2 border-transparent bg-gray-700 bg-clip-padding pointer-events-none",
            "transform transition ease-in-out duration-200",
        )
    ) {
        className(enabled.map { if (it) "translate-x-5" else "translate-x-0 bg-gray-700/60" })
        attr(Aria.hidden, "true")
    }
}

/** Renders a [switchWithLabel]-based `select` element. */
public fun RenderContext.switchField(
    store: Store<Boolean>,
    label: String,
): Tag<HTMLDivElement> = switchWithLabel {
    value(store)
    switchLabel("flex items-center justify-between py-2") {
        +label
        switchToggle {
            renderAsSwitch(enabled)
        }.also(::mergeValidationMessages)
    }
}

/** Renders a [listbox]-based `select` element. */
public fun <D> RenderContext.selectField(
    store: Store<D?>,
    label: String,
    options: List<D>,
    itemTitle: (D) -> String,
    itemIcon: (D) -> Uri? = { null },
): Tag<HTMLDivElement> = listbox<D?> {
    value(store)
    listboxLabel {
        +label
        listboxButton(
            classes(
                "form-select form-field",
                "flex items-center justify-start gap-2",
            )
        ) {
            value.data.render(this) { value ->
                value?.let(itemIcon)?.also { icon("stretch-0 w-5 h-5", it) }
                +(value?.let(itemTitle) ?: "—")
            }
        }.also(::mergeValidationMessages)
    }
    listboxItems("form-field flex flex-col", tag = RenderContext::ul) {
        placement = Placement.bottom
        transition(
            opened,
            "transition duration-100 ease-out",
            "opacity-0 scale-95",
            "opacity-100 scale-100",
            "transition duration-100 ease-in",
            "opacity-100 scale-100",
            "opacity-0 scale-95"
        )
        options.forEach { option ->
            listboxItem(
                option, classes(
                    "cursor-pointer select-none px-3 py-2",
                    "flex items-center justify-start gap-2",
                ), tag = RenderContext::li
            ) {
                className(active.combine(disabled) { a, d ->
                    if (a && !d) "bg-slate-500/20"
                    else if (d) "opacity-50 cursor-default" else ""
                })
                option.let(itemIcon)?.also { icon("stretch-0 w-5 h-5", it) }
                +option.let(itemTitle)
            }
        }
    }
}

/** Renders a [listbox]-based `select` element. */
public inline fun <reified E : Enum<E>> RenderContext.selectField(
    store: Store<E?>,
    label: String,
    noinline itemTitle: (E) -> String = { it.name },
    noinline itemIcon: (E) -> Uri? = { null },
): Tag<HTMLDivElement> = selectField(store, label, enumValues<E>().toList(), itemTitle, itemIcon)


/** Renders a [checkboxGroup]-based `select` element. */
public fun <D> RenderContext.selectMultipleField(
    store: Store<List<D>>,
    label: String,
    options: List<D>,
    itemTitle: (D) -> String,
    itemIcon: (D) -> Uri? = { null },
): Tag<HTMLDivElement> = checkboxGroup<D> {
    value(store)
    popOver {
        checkboxGroupLabel {
            +label
            popOverButton(
                classes(
                    "form-select form-field",
                    "flex items-center justify-start gap-2",
                )
            ) {
                value.data.render(this) { value ->
                    when (value.size) {
                        0 -> {}
                        1 -> icon("stretch-0 w-5 h-5", value.first().let(itemIcon) ?: OutlineHeroIcons.stop)
                        else -> icon("stretch-0 w-5 h-5", OutlineHeroIcons.square_2_stack)
                    }
                    +when (value.size) {
                        0 -> "—"
                        1 -> value.first().let(itemTitle)
                        else -> "${value.size} selected"
                    }
                }
            }.also(::mergeValidationMessages)
        }
        popOverPanel("form-field bg-white flex flex-col max-h-[80vh] overflow-y-auto", tag = RenderContext::ul) {
            clicks.stopPropagation().preventDefault()
            options.forEach { option ->
                checkboxGroupOption(option, tag = RenderContext::li) {
//                className(active.combine(disabled) { a, d ->
//                    if (a && !d) "bg-slate-500/20"
//                    else if (d) "opacity-50 cursor-default" else ""
//                })
                    checkboxGroupOptionToggle(
                        classes(
                            "cursor-pointer select-none px-3 py-2",
                            "flex items-center justify-between gap-2",
                        )
                    ) {
                        checkboxGroupOptionLabel("flex items-center justify-start gap-2", tag = RenderContext::span) {
                            option.let(itemIcon)?.also { icon("stretch-0 w-5 h-5", it) }
                            +option.let(itemTitle)
                        }
                        button {
                            renderAsSwitch(selected)
                        }
                    }
                }
            }
        }
    }
}

/** Renders a [checkboxGroup]-based `select` element. */
public inline fun <reified E : Enum<E>> RenderContext.selectMultipleField(
    store: Store<List<E>>,
    label: String,
    noinline itemTitle: (E) -> String = { it.name },
    noinline itemIcon: (E) -> Uri? = { null },
): Tag<HTMLDivElement> = selectMultipleField(store, label, enumValues<E>().toList(), itemTitle, itemIcon)

/**
 * Renders an `<input>`-based editor for the given [store].
 */
@Deprecated("remove")
public fun RenderContext.inputEditor(
    classes: String? = null,
    store: ValidatingStore<String, Unit, ValidationMessage>,
    init: HtmlTag<HTMLInputElement>.() -> Unit = {},
): HtmlTag<HTMLInputElement> = input(classes) {
    type("text")
    value(store.current)
    customValidity(store.errorMessage)
    keydowns.stopPropagation() // might be cancelled by surrounding data collection otherwise
    keyups.values() handledBy store.update
    changes.values() handledBy store.update
    init()
}

/**
 * Renders an `<input>`-based editor for the given [store].
 */
@Deprecated("remove")
public fun RenderContext.passwordEditor(
    classes: String? = null,
    store: ValidatingStore<String, Unit, ValidationMessage>,
    init: HtmlTag<HTMLInputElement>.() -> Unit = {},
): HtmlTag<HTMLInputElement> = input(classes) {
    type("password")
    value(store.current)
    customValidity(store.errorMessage)
    keydowns.stopPropagation() // might be cancelled by surrounding data collection otherwise
    keyups.values() handledBy store.update
    changes.values() handledBy store.update
    init()
}

/**
 * Renders an `<textfield>`-based editor for the given [store].
 */
@Deprecated("remove")
public fun RenderContext.textFieldEditor(
    classes: String? = null,
    store: ValidatingStore<String, Unit, ValidationMessage>,
    init: HtmlTag<HTMLTextAreaElement>.() -> Unit = {},
): HtmlTag<HTMLTextAreaElement> = textarea(classes) {
    rows(6)
    +store.current
    customValidity(store.errorMessage)
    keydowns.stopPropagation() // might be cancelled by surrounding data collection otherwise
    keyups.values() handledBy store.update
    changes.values() handledBy store.update
    init()
}

/**
 * Renders an `<select>`-based editor for the given [store].
 */
@Deprecated("remove")
public fun <T> RenderContext.selectEditor(
    classes: String? = null,
    store: Store<T>,
    vararg options: T,
    valueProvider: (T) -> String = { it.toString() },
    render: HtmlTag<HTMLOptionElement>.(T) -> Unit = { +it.toString() },
    init: HtmlTag<HTMLSelectElement>.() -> Unit = {},
): HtmlTag<HTMLSelectElement> {
    val update = store.handle<Event> { old, event ->
        (event.currentTarget as? HTMLSelectElement)?.value?.let { value -> options.firstOrNull { valueProvider(it) == value } } ?: old
    }

    return select(classes) {
        options.forEach { option ->
            option {
                value(valueProvider(option))
                selected(store.data.map { valueProvider(it) == valueProvider(option) })
                render(option)
            }
        }
        changes handledBy update
        init()
    }
}

/**
 * Renders an `<select>`-based editor for the given [store].
 */
@Deprecated("remove")
public inline fun <reified E : Enum<E>> RenderContext.selectEditor(
    classes: String? = null,
    store: Store<E>,
    vararg options: E = enumValues(),
    noinline render: HtmlTag<HTMLOptionElement>.(E) -> Unit = { +it.name },
    noinline init: HtmlTag<HTMLSelectElement>.() -> Unit = {},
): HtmlTag<HTMLSelectElement> = selectEditor(
    classes = classes,
    store = store,
    options = options,
    valueProvider = { it.name },
    render = render,
    init = init,
)


/**
 * Renders an `<select>`-based editor for the given [store].
 */
@Deprecated("remove")
public fun <T> RenderContext.multiSelectEditor(
    classes: String? = null,
    store: Store<List<T>>,
    vararg options: T,
    valueProvider: (T) -> String = { it.toString() },
    render: HtmlTag<HTMLOptionElement>.(T) -> Unit = { +it.toString() },
    init: HtmlTag<HTMLSelectElement>.() -> Unit = {},
): HtmlTag<HTMLSelectElement> {
    val update = store.handle<Event> { old, event ->
        (event.currentTarget as? HTMLSelectElement)?.options?.asList()
            ?.mapNotNull { (it as? HTMLOptionElement)?.let { option -> option.value.takeIf { option.selected } } }
            ?.let { values -> options.filter { valueProvider(it) in values } }
            ?: old
    }

    return select(classes) {
        multiple(true)
        options.forEach { option ->
            option {
                value(valueProvider(option))
                selected(store.data.map { it.map(valueProvider) }.map { valueProvider(option) in it })
                render(option)
            }
        }
        changes handledBy update
        init()
    }
}


/**
 * Renders an `<select>`-based editor for the given [store].
 */
@Deprecated("remove")
public inline fun <reified E : Enum<E>> RenderContext.multiSelectEditor(
    classes: String? = null,
    store: Store<List<E>>,
    vararg options: E = enumValues(),
    noinline render: HtmlTag<HTMLOptionElement>.(E) -> Unit = { +it.name },
    noinline init: HtmlTag<HTMLSelectElement>.() -> Unit = {},
): HtmlTag<HTMLSelectElement> = multiSelectEditor(
    classes = classes,
    store = store,
    options = options,
    valueProvider = { it.name },
    render = render,
    init = init,
)

/**
 * Renders an [Uri] editor for the given [store].
 */
@Deprecated("remove")
public fun RenderContext.uriEditor(
    classes: String? = null,
    store: Store<Uri>,
    init: HtmlTag<HTMLInputElement>.() -> Unit = {},
): HtmlTag<HTMLInputElement> = inputEditor(classes, store.mapValidating(lensOf("uri", { it.toString() }, { _, v -> v.toUri() })), init)

@Deprecated("delete")
public sealed interface EditorAction<D> {
    public val name: String
    public val shortcut: Shortcut?
    public val disabled: (Flow<List<ValidationMessage>>) -> Flow<Boolean>
    public val handle: (D) -> Unit

    public data class Save<D>(
        override val name: String = "Save",
        override val shortcut: Shortcut = Keys.Meta + "s",
        override val disabled: (Flow<List<ValidationMessage>>) -> Flow<Boolean> = { !it.valid },
        override val handle: (D) -> Unit,
    ) : EditorAction<D>

    public data class Cancel<D>(
        override val name: String = "Cancel",
        override val shortcut: Shortcut = Keys.Escape,
        override val disabled: (Flow<List<ValidationMessage>>) -> Flow<Boolean> = { flowOf(false) },
        override val handle: (D) -> Unit = {},
    ) : EditorAction<D>

    public data class Delete<D>(
        override val name: String = "Delete",
        override val shortcut: Shortcut? = null,
        override val disabled: (Flow<List<ValidationMessage>>) -> Flow<Boolean> = { flowOf(false) },
        override val handle: (D) -> Unit,
    ) : EditorAction<D>

    public companion object {
        public val DefaultEditorActions: Array<EditorAction<Nothing>> = arrayOf(
            Save {},
            Cancel {},
            Delete {},
        )

        public fun <D> Create(
            name: String = "Create",
            shortcut: Shortcut = Keys.Meta + "s",
            disabled: (Flow<List<ValidationMessage>>) -> Flow<Boolean> = { !it.valid },
            handle: (D) -> Unit,
        ): Save<D> = Save(
            name = name,
            shortcut = shortcut,
            disabled = disabled,
            handle = handle,
        )
    }
}

public val OpenClose.shortcuts: Flow<Pair<KeyboardEvent, Shortcut>>
    get() = openState.data.flatMapLatest { isOpen ->
        Window.keydowns.filter { isOpen }
    }.map { it to shortcutOf(it) }

public fun OpenClose.outsideClicks(element: WithDomNode<*>): Flow<MouseEvent> =
    openState.data.flatMapLatest { isOpen ->
        Window.clicks.filter { event ->
            isOpen && event.composedPath().none { it == element.domNode }
        }
    }

public val HtmlTag<Element>.shortcuts: Flow<Pair<KeyboardEvent, Shortcut>>
    get() = Window.keydowns.filter {
        (domNode == document.activeElement).trace("HAS FOCUS")
    }.map { it to shortcutOf(it) }

public val HtmlTag<Element>.outsideClicks: Flow<MouseEvent>
    get() = Window.clicks.filter { event ->
        domNode == document.activeElement && event.composedPath().none { it == this.domNode }
    }
