package com.bkahlert.hello.fritz2

import com.bkahlert.kommons.js.ConsoleLogger
import com.bkahlert.kommons.json.LenientAndPrettyJson
import dev.fritz2.core.Inspector
import dev.fritz2.core.Lens
import dev.fritz2.core.Store
import dev.fritz2.core.Tag
import dev.fritz2.core.mountSimple
import dev.fritz2.headless.components.CheckboxGroup
import dev.fritz2.headless.components.InputField
import dev.fritz2.headless.components.Listbox
import dev.fritz2.headless.components.SwitchWithLabel
import dev.fritz2.headless.components.TextArea
import dev.fritz2.headless.foundation.ValidationMessages
import dev.fritz2.headless.validation.errorMessage
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.Validation
import dev.fritz2.validation.ValidationMessage
import dev.fritz2.validation.validation
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.StringFormat
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.serializer
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLFieldSetElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLKeygenElement
import org.w3c.dom.HTMLObjectElement
import org.w3c.dom.HTMLOutputElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.HTMLTextAreaElement

private val logger = ConsoleLogger("hello.validation")

/** Converts Fritz2 [ValidationMessages] to native validation messages. */
public fun CheckboxGroup<*, *>.mergeValidationMessages(input: Tag<HTMLButtonElement>) {
    checkboxGroupValidationMessages("hidden", initialize = mergeValidationMessagesInit(input))
}

/** Converts Fritz2 [ValidationMessages] to native validation messages. */
public fun InputField<*>.mergeValidationMessages(input: Tag<HTMLInputElement>) {
    inputValidationMessages("hidden", initialize = mergeValidationMessagesInit(input))
}

/** Converts Fritz2 [ValidationMessages] to native validation messages. */
public fun Listbox<*, *>.mergeValidationMessages(input: Tag<HTMLButtonElement>) {
    listboxValidationMessages("hidden", initialize = mergeValidationMessagesInit(input))
}

/** Converts Fritz2 [ValidationMessages] to native validation messages. */
public fun SwitchWithLabel<*>.mergeValidationMessages(input: Tag<HTMLButtonElement>) {
    switchValidationMessages("hidden", initialize = mergeValidationMessagesInit(input))
}

/** Converts Fritz2 [ValidationMessages] to native validation messages. */
public fun TextArea<*>.mergeValidationMessages(input: Tag<HTMLTextAreaElement>) {
    textareaValidationMessages("hidden", initialize = mergeValidationMessagesInit(input))
}

private fun mergeValidationMessagesInit(input: Tag<HTMLElement>): ValidationMessages<HTMLDivElement>.() -> Unit = {
    msgs.map { messages -> messages.filter { it.isError } } handledBy { errors ->
        input.customValidityAndReport(errors.joinToString("\n") { it.message })
    }
}

/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation) and reports it. */
private fun Tag<HTMLElement>.customValidityAndReport(message: String): Unit {
    // @formatter:off
    when(val node = domNode) {
        is HTMLObjectElement -> { node.setCustomValidity(message); node.reportValidity() }
        is HTMLInputElement -> { node.setCustomValidity(message); node.reportValidity() }
        is HTMLButtonElement -> { node.setCustomValidity(message); node.reportValidity() }
        is HTMLSelectElement -> { node.setCustomValidity(message); node.reportValidity() }
        is HTMLTextAreaElement -> { node.setCustomValidity(message); node.reportValidity() }
        is HTMLKeygenElement -> { node.setCustomValidity(message); node.reportValidity() }
        is HTMLOutputElement -> { node.setCustomValidity(message); node.reportValidity() }
        is HTMLFieldSetElement -> { node.setCustomValidity(message); node.reportValidity() }
        else -> logger.warn("setCustomValidity not supported by element",node)
    }
    // @formatter:on
}


/**
 * Creates a new [ValidatingStore] that contains data derived by a given [lens],
 * and [ValidatingStore.messages] encapsulating exceptions thrown by [Lens.set].
 */
@Deprecated("remove; see Bookmark")
public fun <D, X> Store<D>.mapValidating(
    lens: Lens<D, X>,
): ValidatingStore<X, Unit, ValidationMessage> = object : ValidatingStore<X, Unit, ValidationMessage>(
    initialData = lens.get(current),
    validation = validateCatching { lens.set(current, it.data).also { validated -> update(validated) } }
) {
    init {
        validate(current)
    }
}

/** A flow of error messages with each error message being the concatenation of all errors. */
@Deprecated("remove; see Bookmark")
public val ValidatingStore<*, *, out ValidationMessage>.errorMessage: Flow<String>
    get() = messages.map { messages ->
        messages.filter { it.isError }.joinToString { it.toString() }
    }

/** Creates a [ValidationMessage] from this exception. */
public fun Throwable.toValidationMessage(path: String = ""): ValidationMessage = errorMessage(
    path = path,
    message = message ?: this::class.simpleName ?: "Error",
    details = stackTraceToString()
)

/** Creates a [Validation] creates [ValidationMessage] instances from thrown exceptions. */
@Deprecated("remove; see Bookmark")
public fun <D> validateCatching(block: (Inspector<D>) -> Unit): Validation<D, Unit, ValidationMessage> =
    validation { inspector ->
        kotlin
            .runCatching { block(inspector) }
            .onFailure { add(it.toValidationMessage()) }
    }

@Deprecated("remove; see Bookmark")
public fun <T> validateSerialization(stringFormat: StringFormat, deserializer: DeserializationStrategy<T>): Validation<String, Unit, ValidationMessage> =
    validateCatching { inspector ->
        stringFormat.decodeFromString(deserializer, inspector.data)
    }

@Deprecated("remove; see Bookmark")
public inline fun <reified T> validateSerialization(stringFormat: StringFormat): Validation<String, Unit, ValidationMessage> =
    validateSerialization(stringFormat, serializer<T>())

@Deprecated("remove; see Bookmark")
public fun validateJson(json: Json = LenientAndPrettyJson): Validation<String, Unit, ValidationMessage> =
    validateSerialization(json, serializer<JsonElement>())


/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLObjectElement>.customValidity(message: String): Unit = domNode.setCustomValidity(message)

/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLObjectElement>.customValidity(message: Flow<String>): Unit = mountSimple(job, message, ::customValidity)


/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLInputElement>.customValidity(message: String): Unit = domNode.setCustomValidity(message)

/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLInputElement>.customValidity(message: Flow<String>): Unit = mountSimple(job, message, ::customValidity)


/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLButtonElement>.customValidity(message: String): Unit = domNode.setCustomValidity(message)

/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLButtonElement>.customValidity(message: Flow<String>): Unit = mountSimple(job, message, ::customValidity)


/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLSelectElement>.customValidity(message: String): Unit = domNode.setCustomValidity(message)

/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLSelectElement>.customValidity(message: Flow<String>): Unit = mountSimple(job, message, ::customValidity)


/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLTextAreaElement>.customValidity(message: String): Unit = domNode.setCustomValidity(message)

/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLTextAreaElement>.customValidity(message: Flow<String>): Unit = mountSimple(job, message, ::customValidity)


/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLKeygenElement>.customValidity(message: String): Unit = domNode.setCustomValidity(message)

/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLKeygenElement>.customValidity(message: Flow<String>): Unit = mountSimple(job, message, ::customValidity)


/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLOutputElement>.customValidity(message: String): Unit = domNode.setCustomValidity(message)

/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLOutputElement>.customValidity(message: Flow<String>): Unit = mountSimple(job, message, ::customValidity)


/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLFieldSetElement>.customValidity(message: String): Unit = domNode.setCustomValidity(message)

/** Sets the [customValidity](https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation). */
@Deprecated("use mergeValidationMessages")
public fun Tag<HTMLFieldSetElement>.customValidity(message: Flow<String>): Unit = mountSimple(job, message, ::customValidity)
