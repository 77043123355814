package com.bkahlert.hello.components.applet

import com.bkahlert.hello.components.applet.media.ImageApplet
import com.bkahlert.hello.components.applet.media.VideoApplet
import com.bkahlert.hello.components.applet.media.WebsiteApplet
import com.bkahlert.hello.components.applet.preview.FeaturePreview
import com.bkahlert.hello.components.applet.preview.FeaturePreviewApplet
import com.bkahlert.hello.components.applet.ssh.WsSshApplet
import com.bkahlert.hello.components.move
import com.bkahlert.hello.fritz2.DeprecatedContentBuilder
import com.bkahlert.hello.fritz2.SyncStore
import com.bkahlert.hello.fritz2.app.props.PropStoreFactory
import com.bkahlert.hello.fritz2.app.props.PropsStore
import com.bkahlert.hello.fritz2.components.button
import com.bkahlert.hello.fritz2.components.heroicons.OutlineHeroIcons
import com.bkahlert.hello.fritz2.components.heroicons.SolidHeroIcons
import com.bkahlert.hello.fritz2.components.icon
import com.bkahlert.hello.fritz2.scrollTops
import com.bkahlert.hello.fritz2.syncState
import com.bkahlert.hello.fritz2.verticalScrollCoverageRatios
import com.bkahlert.hello.scrollSmoothlyIntoView
import com.bkahlert.hello.scrollSmoothlyTo
import com.bkahlert.kommons.dom.checkedOwnerDocument
import com.bkahlert.kommons.js.ConsoleLogger
import com.bkahlert.kommons.json.LenientJson
import com.bkahlert.kommons.uri.Uri
import com.bkahlert.kommons.uri.toUri
import dev.fritz2.core.Handler
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Tag
import dev.fritz2.core.WithDomNode
import dev.fritz2.core.classes
import dev.fritz2.core.disabled
import dev.fritz2.core.lensForElement
import dev.fritz2.core.lensOf
import dev.fritz2.core.title
import dev.fritz2.core.type
import dev.fritz2.headless.components.dataCollection
import dev.fritz2.headless.foundation.utils.scrollintoview.ScrollPosition
import dev.fritz2.headless.foundation.utils.scrollintoview.scrollIntoView
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapLatest
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import org.w3c.dom.Element
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import kotlin.time.Duration.Companion.seconds

class Applets(
    val registration: AppletRegistration,
    val propsStore: PropsStore,
    val defaultValue: List<Applet>,
    id: String,
) : SyncStore<List<Applet>> by propsStore.map(id, defaultValue, ListSerializer(AppletSerializer(registration))) {
    private val logger = ConsoleLogger("hello.applets")

    private val router = AppletRouter(this)

    private fun randomId(): String {
        var id: String
        do id = Applet.randomId()
        while (current.any { it.id == id })
        return id
    }

    private val idProvider: (Applet) -> String = { "applet-${it.id}" }

    val selected = router.map(
        lensOf(
            id = "",
            getter = { it?.applet },
            setter = { p, v -> v?.let { AppletRoute.Current(v, p?.edit ?: false) } },
        )
    )
    val edited = router.map(
        lensOf(
            id = "",
            getter = {
                it?.takeIf { it.edit }?.applet?.let { applet ->
                    applet.editor(isNew = current.none { it.id == applet.id })
                        .also { editor ->
                            editor.addOrUpdate handledBy {
                                addOrUpdate(it)
                                router.navTo(AppletRoute.Current(it, false))
                            }
                            editor.delete handledBy {
                                delete(it)
                                router.navTo(AppletRoute.Current(it, false))
                            }
                            editor.cancel handledBy {
                                router.navTo(AppletRoute.Current(it, false))
                            }
                        }
                }
            },
            setter = { p, v -> v?.let { AppletRoute.Current(v.current, true) } },
        )
    )

    val addOrUpdate: Handler<Applet> = handle { applets, applet ->
        val existing = applets.firstOrNull { it.id == applet.id }
        if (existing == null) {
            applets + applet
        } else {
            applets.map { if (it.id == applet.id) applet else it }
        }
    }

    val duplicate: Handler<Applet> = handle { applets, applet ->
        val jsonElement = LenientJson.encodeToJsonElement(applet)
        val jsonObject: JsonObject = jsonElement as? JsonObject ?: error("Applet unexpectedly not encoded to JsonObject but to $jsonElement")
        val duplicate: Applet = LenientJson.decodeFromJsonElement(buildJsonObject {
            put(Applet::id.name, randomId())
            put(Applet::title.name, "Copy of ${applet.title}")
            jsonObject
                .filterKeys { it != Applet::id.name && it != Applet::title.name }
                .forEach { (key, value) -> put(key, value) }
        })
        applets.flatMap { if (it.id == applet.id) listOf(applet, duplicate) else listOf(it) }.also {
            router.navTo(AppletRoute.Current(duplicate, true))
        }
    }

    val replace: Handler<Applet> = handle { applets, applet ->
        applets.map { if (it.id == applet.id) applet else it }.also { selected.update(applet) }
    }

    val rankUp: Handler<Applet> = handle { applets, applet ->
        applets.move(applet, -1).also { selected.update(applet) }
    }
    val rankDown: Handler<Applet> = handle { applets, applet ->
        applets.move(applet, +1).also { selected.update(applet) }
    }

    val delete: Handler<Applet> = handle { applets, applet ->
        val scrollTo = applets.indexOfFirst { it.id == applet.id }.let { index ->
            if (index + 1 in applets.indices) applets[index + 1]
            else applets.getOrNull(index - 1)
        }
        applets.filter { it.id != applet.id }.also { scrollTo?.also { selected.update(applet) } }
    }

    private fun Applet.title() = title.takeUnless { it.isNullOrBlank() }
        ?: registration.findByInstance(this)?.title?.let { "$it: $id" }
        ?: id

    private fun Applet.icon() = icon
        ?: registration.findByInstance(this)?.icon
        ?: SolidHeroIcons.question_mark_circle

    fun render(renderContext: RenderContext) {
        runCatching { (renderContext.unsafeCast<Tag<HTMLElement>>()).trackScrolling() }
            .onFailure { logger.error("Failed to track scrolling", it) }

        val appletAdditionControlsId = "applet-addition-controls"

        renderContext.dataCollection("contents") {
            data(
                data = this@Applets.data,
                idProvider = { it.toString() }, // toString is used on purpose to ensure re-rendering on every applet change
            )

            dataCollectionItems("contents") {
                scrollIntoView(vertical = ScrollPosition.center)
                items.renderEach({ it.toString() }, into = this, batch = false) { applet: Applet ->

                    dataCollectionItem(
                        applet,
                        id = idProvider(applet),
                        classes = "applet",
                    ) {
                        // Maps to the applet unless its edited, in which case, it maps to an
                        // instance that reflects the current changes.
                        val liveApplet: Flow<Applet> = edited.data
                            .flatMapLatest { it?.data?.map { if (it.id == applet.id) it else applet } ?: flowOf(applet) }
                            .distinctUntilChanged()

                        val editing = edited.data.flatMapLatest { it?.data?.map { it.id == applet.id } ?: flowOf(false) }.distinctUntilChanged()
                        editing.mapNotNull { ed -> scrollIntoView.value?.takeIf { ed } } handledBy { scrollIntoView(domNode, it) }

                        div("applet-controls") {
                            div("flex items-center min-w-0") {
                                div("flex items-center justify-center gap-2 opacity-60 overflow-hidden") {
                                    liveApplet.render(this) {
                                        icon("shrink-0 w-4 h-4", it.icon())
                                        div("truncate") { +it.title() }
                                    }
                                }
                            }

                            div("ml-8 flex items-center justify-center gap-3") {
                                button("disabled:pointer-events-none disabled:opacity-60") {
                                    type("button")
                                    disabled(editing)
                                    icon("w-4 h-4", OutlineHeroIcons.squares_plus)
                                    title("Add")
                                    clicks handledBy { domNode.checkedOwnerDocument.getElementById(appletAdditionControlsId)?.scrollSmoothlyIntoView() }
                                }

                                button("disabled:pointer-events-none disabled:opacity-60") {
                                    type("button")
                                    disabled(editing)
                                    icon("w-4 h-4", OutlineHeroIcons.square_2_stack)
                                    title("Duplicate")
                                    clicks.map { applet } handledBy duplicate
                                }

                                button("disabled:pointer-events-none disabled:opacity-60") {
                                    type("button")
                                    disabled(editing)
                                    icon("w-4 h-4", OutlineHeroIcons.pencil_square)
                                    title("Edit")
                                    clicks.map { applet } handledBy { router.navTo(AppletRoute.Current(it, true)) }
                                }

                                div("flex items-center justify-center gap-2") {
                                    button("disabled:pointer-events-none disabled:opacity-60") {
                                        type("button")
                                        disabled(items.map { it.firstOrNull() == applet })
                                        icon("w-4 h-4", OutlineHeroIcons.arrow_small_up)
                                        title("Move up")
                                        clicks.mapNotNull { applet } handledBy rankUp
                                    }
                                    div("flex items-center justify-end space-x-1 opacity-60") {
                                        div {
                                            items.map { it.indexOf(applet) }.render {
                                                div("animate-in spin-in slide-in-from-top") { +"${it + 1}" }
                                            }
                                        }
                                        div("font-extralight text-xs") { +"/" }
                                        div("font-extralight") { items.map { it.size }.render { +"$it" } }
                                    }
                                    button("disabled:pointer-events-none disabled:opacity-60") {
                                        type("button")
                                        disabled(items.map { it.lastOrNull() == applet })
                                        icon("w-4 h-4", OutlineHeroIcons.arrow_small_down)
                                        title("Move down")
                                        clicks.mapNotNull { applet } handledBy rankDown
                                    }
                                }
                            }
                        }

                        div("applet-content flex flex-row-reverse gap-8") {
                            div("flex-grow") {
                                liveApplet.render(this) { applet ->
                                    applet.render(this).apply {
                                        syncState(syncState.map { it.map(lensForElement(applet, Applet::id)) })
                                    }
                                }
                            }
                            edited.data.render { appletEditor ->
                                if (appletEditor != null && appletEditor.current.id == applet.id) {
                                    with(appletEditor) {
                                        render().apply {
                                            className("absolute inset-0 z-10 bg-glass text-default dark:text-invert sm:relative sm:bg-none sm:ml-3")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        renderContext.div("app-item", id = appletAdditionControlsId) {
            div { icon("mx-auto w-12 h-12 text-default dark:text-invert opacity-60", SolidHeroIcons.squares_plus) }
            div("grid grid-cols-[repeat(auto-fit,_minmax(min(20rem,_100%),_1fr))] gap-8 m-8 items-start") {
                registration.forEach { (_, registration) ->
                    button(registration.icon, registration.title, registration.description, simple = true, inverted = true).apply {
                        clicks.map { registration.create(randomId()) } handledBy {
                            addOrUpdate(it)
                            router.navTo(AppletRoute.Current(it, true))
                        }
                    }
                }
            }
        }

        // TODO extract as "dock" or "toolbar" component
        renderContext.div("absolute z-10 top-1/2 -translate-y-1/2 right-0 pointer-coarse:hidden opacity-0 transition group pr-4 hover:pr-6") {
            val withDom = renderContext.unsafeCast<WithDomNode<Element>>()
            className(withDom.verticalScrollCoverageRatios.mapLatest { it < 0.75 }.mapLatest { if (it) "opacity-100" else "" })

            div(
                classes(
                    "flex flex-col items-center justify-center overflow-hidden transition",
                    "group-hover:my-4 group-hover:ml-4",
                    "rounded-full bg-glass [--glass-opacity:0.1] group-hover:[--glass-opacity:0.5]",
                    "group-hover:scale-100",
                    "scale-75 origin-right group-hover:scale-100",
                )
            ) {
                fun iconButton(icon: Uri, caption: String, customize: HtmlTag<HTMLButtonElement>.() -> Unit = {}) =
                    button("w-8 h-8 relative overflow-hidden text-default dark:text-invert enabled:hover:bg group/button transition") {
                        type("button")
                        icon("absolute inset-1 scale-75 group-hover/button:scale-100", icon)
                        title(caption)
                        customize()
                    }

                iconButton(OutlineHeroIcons.arrow_long_up, "Scroll to top") {
                    clicks.map { withDom.domNode } handledBy { it.scrollSmoothlyTo(top = 0) }
                }
                data.renderEach(
                    idProvider = { it.toString() }, // toString is used on purpose to ensure re-rendering on every applet change
                ) { applet: Applet ->
                    iconButton(
                        applet.icon(),
                        "Scroll to ${applet.title()}"
                    ) { clicks.map { applet } handledBy selected.update }
                }
                iconButton(
                    OutlineHeroIcons.squares_plus,
                    "Add applet",
                ) {
                    clicks.mapNotNull { withDom.domNode.checkedOwnerDocument.getElementById("applet-addition-controls") } handledBy {
                        it.scrollSmoothlyIntoView()
                    }
                }
                iconButton(OutlineHeroIcons.arrow_long_down, "Scroll to bottom") {
                    clicks.map { withDom.domNode } handledBy { it.scrollSmoothlyTo(top = it.scrollHeight) }
                }
            }
        }
    }

    override fun toString(): String = "Applets($current)"

    private var blockTracking = true
    private fun Tag<HTMLElement>.trackScrolling() {
        scrollTops
            .mapNotNull { it.takeUnless { blockTracking } }
            .map { current.find { applet -> applet.isInView(idProvider) } }
            .filterNotNull()
            .distinctUntilChanged()
            .debounce(1.seconds) handledBy selected.update
    }

    init {
        selected.data
            .filterNotNull()
            .distinctUntilChanged() handledBy { applet ->
            delay(0.1.seconds)
            if (!applet.isInView(idProvider)) {
                applet.findElement(idProvider)?.scrollSmoothlyIntoView()
            }
            blockTracking = false
        }
    }

    companion object : PropStoreFactory<List<Applet>> {
        private val Element.scrollMiddle get() = scrollTop.toInt() + clientHeight / 2
        private fun Applet.findElement(idProvider: (Applet) -> String): HTMLElement? =
            document.getElementById(idProvider(this))?.unsafeCast<HTMLElement>()

        private fun Applet.isInView(idProvider: (Applet) -> String): Boolean {
            val appletElement = findElement(idProvider) ?: return false
            val offsetThreshold = appletElement.closest(".app-scroll-container")?.scrollMiddle ?: return false
            return offsetThreshold >= appletElement.offsetTop && offsetThreshold <= appletElement.offsetTop + appletElement.clientHeight
        }

        override val DEFAULT_KEY: String = "applets"
        override val DEFAULT_VALUE: List<Applet> = buildList {
            add(
                ImageApplet(
                    id = "nyan-cat",
                    title = "Nyan Cat",
                    src = NyanCatSrc.toUri(),
                    aspectRatio = AspectRatio.video
                )
            )
            add(
                VideoApplet(
                    id = "rick-astley",
                    title = "Rick Astley",
                    src = Uri("https://www.youtube.com/embed/dQw4w9WgXcQ"),
                )
            )
            add(
                WebsiteApplet(
                    id = "impossible-color",
                    title = "Impossible color",
                    src = Uri("https://en.wikipedia.org/wiki/Impossible_color"),
                    aspectRatio = AspectRatio.stretch,
                )
            )
            FeaturePreview.values().mapTo(this) {
                FeaturePreviewApplet(
                    id = "feature-preview-${it.name}",
                    feature = it,
                )
            }
        }

        override fun invoke(propsStore: PropsStore, defaultValue: List<Applet>, id: String): Applets =
            Applets(AppletRegistration().apply {
                register<FeaturePreviewApplet>(
                    "feature-preview",
                    title = "Feature Preview",
                    description = "Demo of a future feature",
                    icon = SolidHeroIcons.star
                )
                register<ImageApplet>(
                    "image",
                    title = "Image",
                    description = "Displays an image",
                    icon = SolidHeroIcons.photo
                )
                register<VideoApplet>(
                    "video",
                    title = "Video",
                    description = "Embeds a video",
                    icon = SolidHeroIcons.video_camera
                )
                register<WebsiteApplet>(
                    "website", "embed",
                    title = "Website",
                    description = "Embeds an external website",
                    icon = SolidHeroIcons.window
                )
                register<WsSshApplet>(
                    "ws-ssh",
                    title = "SSH",
                    description = """Connect to a SSH server via a <a href="https://github.com/bkahlert/ws-ssh">WS-SSH proxy</a>.""",
                    icon = SolidHeroIcons.command_line,
                )
            }, propsStore, defaultValue, id)
    }
}

@JsModule("./nyancat.svg")
private external val NyanCatSrc: String

fun RenderContext.panel(
    aspectRatio: AspectRatio? = null,
    content: DeprecatedContentBuilder? = null,
): HtmlTag<HTMLDivElement> = div("panel") {
    val ar = aspectRatio ?: AspectRatio.none
    div(classes("panel-content", ar.classes)) {
        ar.wrap(this) { content?.invoke(this) }
    }
}
